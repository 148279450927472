import React, { useEffect, useState, useRef } from 'react';
import { createForm } from 'rc-form';
import { useLocation } from 'react-router-dom';
import MsgMask from '../../../component/MsgMask/MsgMask';
import queryString from 'query-string';
import {
	Flex,
	InputItem,
	WhiteSpace,
	Checkbox,
	WingBlank,
	Button,
	Toast,
} from 'antd-mobile';
import styled from './RealName.module.scss';
import { baseURL } from '../../../private/index';

const RealName = (props) => {
	const location = useLocation();
	const childRef = useRef();
	const { cid, signUrl } = queryString.parse(location.search);
	const [userInfo, setUserInfo] = useState({
		name: '1',
		idcard: '',
		phone: '',
		checked:false
	});
	useEffect(() => {
		React.$$.getUserInfo().then((res) => {
			setUserInfo({
				name: res.rows.name,
				idcard: res.rows.idcardNum,
				phone: res.rows.mobileNum,
				checked:false
			});
		});
	}, []);
	const { getFieldProps, getFieldError, getFieldsError } = props.form;
	// 表单完整认证
	const valiFrom = () => {
		return new Promise((resolve, reject) => {
			let valiUserInfo = true;
			Object.values(userInfo).map((item, key) => {
				if(!item) valiUserInfo = false
			});
			if(valiUserInfo){
				resolve(valiUserInfo)
			}else{
				return Toast.info('请填写完整表单');
			}	
		});
	};
	const setName = (e) => {
		console.log('setname', e);
	};
	// 实名认证
	const msgSubmit = () => {
		valiFrom().then(()=>{
			//查询用当前户实名状态，防止数据延迟
			React.$$.quertCer().then((res) => {
				// 实名认证;
				if (res.rows) {
					//已实名
					Toast.info('已实名认证');
				}else{
					React.$$.certificate().then((res)=>{
						window.location.href = res.rows
					})
				}
			});
			// React.$$.certification().then(() => {
			// 	childRef.current.changeShowMask(true);
			// });
		})
	};
	//签署合同
	const faceSubmit = () => {
		valiFrom().then(()=>{
			//查询用当前户实名状态，防止数据延迟
			React.$$.quertCer().then((res) => {
				// 待签署合同;
				if (res.rows) {
					//已实名
					window.location.href = signUrl
				}else{
					Toast.info('请先实名认证');
				}
			});
			// let redirectPath = window.location.origin +`/glorioushtml/FaceResult?signUrl=${signUrl}&cid=${cid}`;
			// React.$$.faceAuth(redirectPath).then((res) => {
			// 	window.location.href = res.rows;
			// });
		})
	};
	//协议
	const agreement= () => {
		window.location.href =window.location.origin +`/glorioushtml/Agreement`;
	};
	
	//错误提示点击事件
	const onErrorClick = (error) => {
		Toast.info(error);
	};
	const AgreeItem = Checkbox.AgreeItem;
	return (
		<div className={styled.page}>
			<InputItem
				placeholder="请输入身份证号"
				value={userInfo.idcard}
				editable={false}
			>
				身份证号
				<span style={{ color: 'red', fontSize: '16px' }}>*</span>：
			</InputItem>
			<InputItem
				placeholder="请输入姓名"
				value={userInfo.name}
				editable={false}
			>
				姓名
				<span style={{ color: 'red', fontSize: '16px' }}>*</span>：
			</InputItem>
			<InputItem
				placeholder="请输入手机号"
				value={userInfo.phone}
				editable={false}
			>
				手机号
				<span style={{ color: 'red', fontSize: '16px' }}>*</span>：
			</InputItem>
			<WingBlank size="lg" className={styled.authButton}>
				<Flex>
					<Flex.Item>
						<Button size="large" onClick={msgSubmit}>
							实名认证
						</Button>
					</Flex.Item>
					<Flex.Item>
						<Button
							type="primary"
							size="large"
							onClick={faceSubmit}
						>
							签署合同
						</Button>
					</Flex.Item>
				</Flex>
			</WingBlank>
			<Flex className={styled.agreement}>
				<Flex.Item>
					<AgreeItem data-seed="logId"  onChange={e => userInfo.checked=e.target.checked }>
						我已阅读并同意 <a onClick={(e) => { e.preventDefault();agreement(); }}>《数字证书使用协议》</a>
					</AgreeItem>
				</Flex.Item>
     		</Flex>
			<MsgMask
				ref={childRef}
				phone={userInfo.phone}
				cid={cid}
				signUrl={signUrl}
			/>
		</div>
	);
};
export default createForm()(RealName);
