import React, { useState, useEffect } from 'react';
import { TabBar } from 'antd-mobile';
import { useHistory, useLocation } from 'react-router-dom';
import styled from './TabbarNav.module.scss';
const TabbarNav = (props, ref) => {
	const history = useHistory();
	const location = useLocation();
	const [TabBarIndex, setTabBarIndex] = useState(location.pathname);
	useEffect(() => {
		if (TabBarIndex == location.pathname) return;
		const path = `${TabBarIndex}`;
		history.push({
			pathname: path,
		});
	}, [TabBarIndex]);
	return (
		<div className={styled.tabbar}>
			{

				<TabBar>
					<TabBar.Item
						icon={
							<svg
								t="1617793304087"
								className="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="2055"
								width="16"
								height="16"
							>
								<path
									d="M754.585 77c45.668 0 83.37 34.091 89.018 78.2h9.936c60.396 0 109.47 48.444 110.446 108.574L964 265.6v552c0 60.363-48.47 109.41-108.634 110.385l-1.827 0.015H165.461c-60.396 0-109.47-48.444-110.446-108.574L55 817.6v-552c0-60.363 48.47-109.41 108.634-110.385l1.827-0.015 30.647-0.001c5.587-43.63 42.536-77.458 87.534-78.187l1.485-0.012h469.458z m149.582 417.011H114.832V817.6c0 27.666 22.217 50.146 49.792 50.593l0.837 0.007h688.078c27.682 0 50.174-22.204 50.621-49.763l0.007-0.837V494.011z m-708.79-279.012l-29.916 0.001c-27.682 0-50.174 22.204-50.621 49.763l-0.007 0.837-0.001 168.611h80.545V214.999zM754.585 136.8H285.127c-16.287 0-29.534 13.007-29.909 29.194l-0.008 0.706v267.511h529.291V166.7c0-16.277-13.014-29.517-29.21-29.892l-0.706-0.008z m98.954 78.2l-9.205-0.001v219.212h59.833V265.6c0-27.666-22.216-50.146-49.79-50.593l-0.838-0.007z m-391.934-42.125l50.164 50.103 49.714-49.653c6.612-6.603 17.269-6.69 23.987-0.26l0.266 0.26 0.015 0.015c6.593 6.601 6.674 17.243 0.246 23.943l-0.26 0.266-47.41 47.35L582 244.9c10.162 0 18.4 8.238 18.4 18.4 0 10.162-8.238 18.4-18.4 18.4h-49.488v23h65.597c10.162 0 18.4 8.238 18.4 18.4 0 10.162-8.238 18.4-18.4 18.4h-65.597v42.54c0 10.056-8.062 18.229-18.075 18.407l-0.334 0.003c-10.056 0-18.23-8.062-18.408-18.076l-0.003-0.334V341.5h-67.897c-10.162 0-18.4-8.238-18.4-18.4 0-10.162 8.238-18.4 18.4-18.4h67.897v-23h-47.186c-10.162 0-18.4-8.238-18.4-18.4 0-10.162 8.238-18.4 18.4-18.4h36.704l-47.858-47.801c-6.69-6.681-6.696-17.52-0.015-24.209l0.015-0.015c6.7-6.691 17.553-6.691 24.253 0z"
									fill="#333333"
									p-id="2056"
								></path>
							</svg>
						}
						selectedIcon={
							<svg
								t="1617793351997"
								className="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="2202"
								width="16"
								height="16"
							>
								<path
									d="M754.585 77c45.668 0 83.37 34.091 89.018 78.2h9.936c60.396 0 109.47 48.444 110.446 108.574L964 265.6v552c0 60.363-48.47 109.41-108.634 110.385l-1.827 0.015H165.461c-60.396 0-109.47-48.444-110.446-108.574L55 817.6v-552c0-60.363 48.47-109.41 108.634-110.385l1.827-0.015 30.647-0.001c5.587-43.63 42.536-77.458 87.534-78.187l1.485-0.012h469.458z m149.582 417.011H114.832V817.6c0 27.666 22.217 50.146 49.792 50.593l0.837 0.007h688.078c27.682 0 50.174-22.204 50.621-49.763l0.007-0.837V494.011z m-708.79-279.012l-29.916 0.001c-27.682 0-50.174 22.204-50.621 49.763l-0.007 0.837-0.001 168.611h80.545V214.999zM754.585 136.8H285.127c-16.287 0-29.534 13.007-29.909 29.194l-0.008 0.706v267.511h529.291V166.7c0-16.277-13.014-29.517-29.21-29.892l-0.706-0.008z m98.954 78.2l-9.205-0.001v219.212h59.833V265.6c0-27.666-22.216-50.146-49.79-50.593l-0.838-0.007z m-391.934-42.125l50.164 50.103 49.714-49.653c6.612-6.603 17.269-6.69 23.987-0.26l0.266 0.26 0.015 0.015c6.593 6.601 6.674 17.243 0.246 23.943l-0.26 0.266-47.41 47.35L582 244.9c10.162 0 18.4 8.238 18.4 18.4 0 10.162-8.238 18.4-18.4 18.4h-49.488v23h65.597c10.162 0 18.4 8.238 18.4 18.4 0 10.162-8.238 18.4-18.4 18.4h-65.597v42.54c0 10.056-8.062 18.229-18.075 18.407l-0.334 0.003c-10.056 0-18.23-8.062-18.408-18.076l-0.003-0.334V341.5h-67.897c-10.162 0-18.4-8.238-18.4-18.4 0-10.162 8.238-18.4 18.4-18.4h67.897v-23h-47.186c-10.162 0-18.4-8.238-18.4-18.4 0-10.162 8.238-18.4 18.4-18.4h36.704l-47.858-47.801c-6.69-6.681-6.696-17.52-0.015-24.209l0.015-0.015c6.7-6.691 17.553-6.691 24.253 0z"
									fill="#1296db"
									p-id="2203"
								></path>
							</svg>
						}
						selected={TabBarIndex == '/MonthlySalary'}
						onPress={() => {
							setTabBarIndex('/MonthlySalary');
						}}
						title="工资查询"
						key="MonthlySalary"
					></TabBar.Item>
					<TabBar.Item
						icon={
							<svg
								t="1617793398183"
								className="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="3067"
								width="16"
								height="16"
							>
								<path
									d="M434.36 922c0 18.85-13.91 34.6-31.97 37.51v0.49h-270.9C93.87 960 63 928.1 63 888.58V71.94C63 32.64 93.68 0.52 131.48 0.52h760.03c37.64 0 68.48 31.9 68.48 71.42V447.4c0 20.07-16.42 36.5-36.5 36.5-20.07 0-36.5-16.42-36.5-36.5V268.16c0-0.73 0.03-1.45 0.07-2.17l-1.59-190.51-745.95 1.99-2.32 807.73 74.92-0.34c2.58-0.56 5.26-0.86 8-0.86h176.24c0.3 0 0.61 0.02 0.91 0.02l5.12-0.02v0.49c18.06 2.91 31.97 18.66 31.97 37.51zM265 188c-22 0-40 18-40 40s18 40 40 40h496c22 0 40-18 40-40s-18-40-40-40H265z m283 249.94c0-22-18-40-40-40H264.24c-22 0-40 18-40 40s18 40 40 40H508c22 0 40-18 40-40zM436 608H263.76c-22 0-40 18-40 40s18 40 40 40H436c22 0 40-18 40-40s-18-40-40-40z m457.69 120.54a43.055 43.055 0 0 1-11.21 47.79l-27.73 26.55 6.49 37.17a41.888 41.888 0 0 1-17.7 41.89 43.649 43.649 0 0 1-44.83 0l-31.86-16.52-31.85 17.1c-13.81 8.26-31.04 8.26-44.83 0a45.37 45.37 0 0 1-10.62-10.62 40.116 40.116 0 0 1-7.08-31.27l5.9-37.17-27.73-27.14c-11.63-11.32-15.56-28.4-10.03-43.66a42.501 42.501 0 0 1 34.22-27.73l37.17-5.31 15.93-32.45a43.08 43.08 0 0 1 38.94-24.19h5.9a40.73 40.73 0 0 1 33.04 23.01l15.93 29.49 37.76 5.31c15.66 2.17 28.85 12.86 34.19 27.75zM838 750.1l-38.72-5.68a21.729 21.729 0 0 1-14.98-10.84l-17.03-35.11-17.55 35.11a19.6 19.6 0 0 1-14.46 10.84l-39.24 5.68 28.39 27.36a19.64 19.64 0 0 1 5.68 17.04l-6.71 39.23 34.59-18.59c5.8-3.12 12.78-3.12 18.58 0l34.59 18.07-6.71-38.72a19.64 19.64 0 0 1 5.68-17.04L838 750.1z m186 17.9c0 141.38-114.62 256-256 256S512 909.38 512 768s114.62-256 256-256 256 114.62 256 256z m-63.82-1.79c0-106.64-86.45-193.09-193.09-193.09S574 659.57 574 766.21 660.45 959.3 767.09 959.3s193.09-86.45 193.09-193.09z"
									p-id="3068"
								></path>
							</svg>
						}
						selectedIcon={
							<svg
								t="1617793398183"
								className="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="3067"
								width="16"
								height="16"
							>
								<path
									d="M434.36 922c0 18.85-13.91 34.6-31.97 37.51v0.49h-270.9C93.87 960 63 928.1 63 888.58V71.94C63 32.64 93.68 0.52 131.48 0.52h760.03c37.64 0 68.48 31.9 68.48 71.42V447.4c0 20.07-16.42 36.5-36.5 36.5-20.07 0-36.5-16.42-36.5-36.5V268.16c0-0.73 0.03-1.45 0.07-2.17l-1.59-190.51-745.95 1.99-2.32 807.73 74.92-0.34c2.58-0.56 5.26-0.86 8-0.86h176.24c0.3 0 0.61 0.02 0.91 0.02l5.12-0.02v0.49c18.06 2.91 31.97 18.66 31.97 37.51zM265 188c-22 0-40 18-40 40s18 40 40 40h496c22 0 40-18 40-40s-18-40-40-40H265z m283 249.94c0-22-18-40-40-40H264.24c-22 0-40 18-40 40s18 40 40 40H508c22 0 40-18 40-40zM436 608H263.76c-22 0-40 18-40 40s18 40 40 40H436c22 0 40-18 40-40s-18-40-40-40z m457.69 120.54a43.055 43.055 0 0 1-11.21 47.79l-27.73 26.55 6.49 37.17a41.888 41.888 0 0 1-17.7 41.89 43.649 43.649 0 0 1-44.83 0l-31.86-16.52-31.85 17.1c-13.81 8.26-31.04 8.26-44.83 0a45.37 45.37 0 0 1-10.62-10.62 40.116 40.116 0 0 1-7.08-31.27l5.9-37.17-27.73-27.14c-11.63-11.32-15.56-28.4-10.03-43.66a42.501 42.501 0 0 1 34.22-27.73l37.17-5.31 15.93-32.45a43.08 43.08 0 0 1 38.94-24.19h5.9a40.73 40.73 0 0 1 33.04 23.01l15.93 29.49 37.76 5.31c15.66 2.17 28.85 12.86 34.19 27.75zM838 750.1l-38.72-5.68a21.729 21.729 0 0 1-14.98-10.84l-17.03-35.11-17.55 35.11a19.6 19.6 0 0 1-14.46 10.84l-39.24 5.68 28.39 27.36a19.64 19.64 0 0 1 5.68 17.04l-6.71 39.23 34.59-18.59c5.8-3.12 12.78-3.12 18.58 0l34.59 18.07-6.71-38.72a19.64 19.64 0 0 1 5.68-17.04L838 750.1z m186 17.9c0 141.38-114.62 256-256 256S512 909.38 512 768s114.62-256 256-256 256 114.62 256 256z m-63.82-1.79c0-106.64-86.45-193.09-193.09-193.09S574 659.57 574 766.21 660.45 959.3 767.09 959.3s193.09-86.45 193.09-193.09z"
									p-id="3068"
									fill="#1296db"
								></path>
							</svg>
						}
						selected={TabBarIndex == '/ContractList'}
						onPress={() => {
							setTabBarIndex('/ContractList');
						}}
						title="合同列表"
						key="ContractList"
					></TabBar.Item>
					<TabBar.Item
						icon={
							<svg t="1642040626320" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3508" width="16" height="16"><path d="M743.40057 457.9c7.9 0.2 15.5-2.8 21.1-8.3 5.7-5.5 8.9-12.9 9.1-20.8-0.3-16.4-13.8-29.4-30.2-29.1H575.60057L676.40057 284c5.2-5.8 7.7-13.5 7-21.3-0.7-7.7-4.6-14.9-10.8-19.6-12.9-10.3-31.7-8.7-42.6 3.7l-133.2 153h12.4L375.90057 246.9c-11-12.4-29.7-14-42.6-3.7-6.1 4.8-10 11.9-10.7 19.7-0.7 7.7 1.8 15.4 6.9 21.3l100.7 115.7H262.50057c-7.9-0.2-15.5 2.8-21.2 8.3-5.7 5.5-8.9 12.9-9.1 20.8 0 16.1 13.5 29.1 30.3 29.1H470.00057v137.8H262.50057c-7.9-0.2-15.5 2.8-21.2 8.3-5.7 5.5-8.9 13-9.1 20.9 0 16 13.5 29.1 30.3 29.1H470.00057V812c0 16.1 13.4 29.2 30.3 29.2 16.4 0.3 30-12.8 30.3-29.2V653.9h212.7c7.9 0.2 15.5-2.8 21.2-8.3 5.7-5.5 8.9-13 9.1-20.9-0.4-16.4-13.9-29.4-30.3-29.1H530.60057V457.9h212.8z" fill="#515151" p-id="3509"></path><path d="M492.70057 992.8H490.00057c-130.4 0-253.3-49.9-345.8-140.6C52.60057 763 0.00057 639 0.00057 511.9-0.09943 447.1 12.90057 384 38.80057 324.5 89.20057 208.4 182.10057 117.6 300.40057 68.9c60.9-25.1 123.9-38.1 191-37.6 101 0 198.1 29.8 280.6 86.2 80.7 54.8 142.2 131.3 177.7 221.4 6.1 15.4-1.5 32.9-16.9 39-15.4 6.2-32.9-1.5-39-16.9-31.1-78.8-84.9-145.8-155.7-193.8-72.6-49.6-158-75.8-246.9-75.8h-0.9c-57.7 0-113.9 11.2-167.2 33-103.6 42.8-185 122.3-229.1 224-22.6 51.9-34 106.9-33.9 163.4 0 111 46 219.3 126.1 297.2 81.3 79.7 189.3 123.5 304 123.5 0.8 0.1 1.5 0.1 2.3 0 91.8 0 182.7-29.4 256.6-82.9 35.8-26.2 67.2-57.5 93.4-93 26.4-35.9 46.7-75.4 60.4-117.6 5.1-15.7 22.2-24.5 37.9-19.3 15.8 5.1 24.4 22.1 19.3 37.9-15.7 48.3-38.9 93.6-69.1 134.6-29.8 40.5-65.6 76.2-106.4 106-84.2 61.1-187.7 94.6-291.9 94.6z" fill="#515151" p-id="3510"></path><path d="M939.30057 437.2c-0.1 0.2-0.4 0.3-0.7 0.2L792.80057 354c-0.2-0.1-0.2-0.3 0-0.4l230.6-61.8c0.3-0.1 0.4 0.1 0.2 0.3l-84.3 145.1z m-0.3 0.5" fill="#515151" p-id="3511"></path></svg>
						}
						selectedIcon={
							<svg t="1642040626320" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3508" width="16" height="16"><path d="M743.40057 457.9c7.9 0.2 15.5-2.8 21.1-8.3 5.7-5.5 8.9-12.9 9.1-20.8-0.3-16.4-13.8-29.4-30.2-29.1H575.60057L676.40057 284c5.2-5.8 7.7-13.5 7-21.3-0.7-7.7-4.6-14.9-10.8-19.6-12.9-10.3-31.7-8.7-42.6 3.7l-133.2 153h12.4L375.90057 246.9c-11-12.4-29.7-14-42.6-3.7-6.1 4.8-10 11.9-10.7 19.7-0.7 7.7 1.8 15.4 6.9 21.3l100.7 115.7H262.50057c-7.9-0.2-15.5 2.8-21.2 8.3-5.7 5.5-8.9 12.9-9.1 20.8 0 16.1 13.5 29.1 30.3 29.1H470.00057v137.8H262.50057c-7.9-0.2-15.5 2.8-21.2 8.3-5.7 5.5-8.9 13-9.1 20.9 0 16 13.5 29.1 30.3 29.1H470.00057V812c0 16.1 13.4 29.2 30.3 29.2 16.4 0.3 30-12.8 30.3-29.2V653.9h212.7c7.9 0.2 15.5-2.8 21.2-8.3 5.7-5.5 8.9-13 9.1-20.9-0.4-16.4-13.9-29.4-30.3-29.1H530.60057V457.9h212.8z" fill="#1296db" p-id="3509"></path><path d="M492.70057 992.8H490.00057c-130.4 0-253.3-49.9-345.8-140.6C52.60057 763 0.00057 639 0.00057 511.9-0.09943 447.1 12.90057 384 38.80057 324.5 89.20057 208.4 182.10057 117.6 300.40057 68.9c60.9-25.1 123.9-38.1 191-37.6 101 0 198.1 29.8 280.6 86.2 80.7 54.8 142.2 131.3 177.7 221.4 6.1 15.4-1.5 32.9-16.9 39-15.4 6.2-32.9-1.5-39-16.9-31.1-78.8-84.9-145.8-155.7-193.8-72.6-49.6-158-75.8-246.9-75.8h-0.9c-57.7 0-113.9 11.2-167.2 33-103.6 42.8-185 122.3-229.1 224-22.6 51.9-34 106.9-33.9 163.4 0 111 46 219.3 126.1 297.2 81.3 79.7 189.3 123.5 304 123.5 0.8 0.1 1.5 0.1 2.3 0 91.8 0 182.7-29.4 256.6-82.9 35.8-26.2 67.2-57.5 93.4-93 26.4-35.9 46.7-75.4 60.4-117.6 5.1-15.7 22.2-24.5 37.9-19.3 15.8 5.1 24.4 22.1 19.3 37.9-15.7 48.3-38.9 93.6-69.1 134.6-29.8 40.5-65.6 76.2-106.4 106-84.2 61.1-187.7 94.6-291.9 94.6z" fill="#1296db" p-id="3510"></path><path d="M939.30057 437.2c-0.1 0.2-0.4 0.3-0.7 0.2L792.80057 354c-0.2-0.1-0.2-0.3 0-0.4l230.6-61.8c0.3-0.1 0.4 0.1 0.2 0.3l-84.3 145.1z m-0.3 0.5" fill="#1296db" p-id="3511"></path></svg>
						}
						selected={TabBarIndex == '/SalarySheet'}
						onPress={() => {
							setTabBarIndex('/SalarySheet');
						}}
						title="工资条"
						key="ContractList"
					></TabBar.Item>
				</TabBar>
			}

		</div>
	);
};
export default TabbarNav;
