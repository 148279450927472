import React from 'react';
import { Flex, WingBlank, ImagePicker, WhiteSpace, Badge, Toast } from 'antd-mobile';
import styled from './ContractItem.module.scss';
import common from '../../utils/common'
const ContractItem = (props) => {
	const { item, history } = props;
	console.log('查询', props);
	const signHandler = () => {
		//查询用当前户实名状态，防止数据延迟
		React.$$.quertCer().then((res) => {
			if (item.status != 3) {
				React.$$.previewAppUrl(item.id).then((res) => {
					window.location.href = res.rows;
				});
				return;
			}
			// 待签署合同;
			if (res.rows) {
				//已实名
				window.location.href = item.signUrl;
			} else {
				// 未实名
				history.push({
					pathname: '/realname',
					search: `id=1&signUrl=${item.signUrl}`,
				});
			}
		});
	};
	const onChange = (files, type, index) => {
		compressImg(files[0], 0)
	};
	const onChange1 = (files, type, index) => {
		compressImg(files[0], 1)
	};
	const onChange2 = (files, type, index) => {
		compressImg(files[0], 2)
	};
	const onChange3 = (files, type, index) => {
		compressImg(files[0], 3)
	};
	const onChange4 = (files, type, index) => {
		compressImg(files[0], 4)
	};
	const compressImg = (files, type) => {
		common.compressImg(files.file).then(fileData => {
			var data = new FormData()
			data.append('type', type)
			data.append('businessId', item.id)
			data.append('file', fileData, files.file.name)
			React.$$.idCardUpload(data).then((res) => {
				if (res.code === 0) {
					Toast.info(res.msg);
					setTimeout(() => {
						window.location.reload()
					}, 500)
				}
			})
		})
	};
	return (
		<div >
			<WingBlank className={styled.itemView} size="sm">
				<Flex className={styled.bold} justify="start" align="end" onClick={signHandler}>
					<div>签署人：</div>
					<div className={styled.signer}>
						{item.name}
						{item.pactStatus == 2 ? (
							<Badge
								text="已实名"
								style={{
									marginLeft: '6px',
									backgroundColor: '#1296db',
								}}
							/>
						) : (
							<Badge
								text="未实名"
								style={{
									marginLeft: '6px',
									backgroundColor: '#1296db',
								}}
							/>
						)}
					</div>
				</Flex>
				<Flex className={styled.bold} onClick={signHandler}>
					<div>身份证号：</div>
					<Flex.Item>{item.identity}</Flex.Item>
				</Flex>
				<Flex onClick={signHandler}>
					<div>合同名称：</div>
					<Flex.Item>{item.title}</Flex.Item>
				</Flex>
				<Flex onClick={signHandler}>
					<div>签署有效期：</div>
					<Flex.Item>{item.urlExpire}</Flex.Item>
				</Flex>
				<Flex>
					<div>身份证正面：</div>
					<Flex.Item>{item.frontId ? '已上传' : '未上传'}</Flex.Item>
					<Flex.Item>
						<ImagePicker className={styled.img} onChange={onChange} disableDelete length="1" />
					</Flex.Item>
				</Flex>
				<Flex>
					<div>身份证背面：</div>
					<Flex.Item>{item.backId ? '已上传' : '未上传'}</Flex.Item>
					<Flex.Item>
						<ImagePicker className={styled.img} onChange={onChange1} disableDelete length="1" />
					</Flex.Item>
				</Flex>
				<Flex>
					<div>银行卡：</div>
					<Flex.Item>{item.blankId ? '已上传' : '未上传'}</Flex.Item>
					<Flex.Item>
						<ImagePicker className={styled.img} onChange={onChange2} disableDelete length="1" />
					</Flex.Item>
				</Flex>
				<Flex>
					<div>体检报告：</div>
					<Flex.Item>{item.reportId ? '已上传' : '未上传'}</Flex.Item>
					<Flex.Item>
						<ImagePicker className={styled.img} onChange={onChange3} disableDelete length="1" />
					</Flex.Item>
				</Flex>
				<Flex>
					<div>离职证明：</div>
					<Flex.Item>{item.resignationId ? '已上传' : '未上传'}</Flex.Item>
					<Flex.Item>
						<ImagePicker className={styled.img} onChange={onChange4} disableDelete length="1" />
					</Flex.Item>
				</Flex>
			</WingBlank>
		</div>
	);
};
export default ContractItem;
