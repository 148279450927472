import React, { useState, useEffect } from 'react';
import styled from './SalarySheet.module.scss';
import { Redirect, useHistory } from 'react-router-dom';
import { Flex, DatePicker, Icon, Button } from 'antd-mobile';
import moment from 'moment';

import Banner from './../../assets/images/salaryBanner.png';


const SalarySheet = () => {
    const [salaryYear, setSalaryYear] = useState(moment().format('YYYY'));
    const [salaryList, setSalartList] = useState([]);
    const history = useHistory();
    const BASEURL = 'http://gy.shaohuahr.com'
    useEffect(() => {
        React.$$.salaryTemplateDetail(salaryYear)
            .then((res) => {
                const list = res.rows.map((item, key) => {
                    let { monthPart, } = item;
                    item.monthPart = monthPart.slice(0, 4) + '/' + monthPart.slice(4);
                    return item;
                });
                setSalartList(list);
            })
            .catch((error) => { });
    }, [salaryYear]);
    let userInfo = React.$store.store('userInfo');
    if (!userInfo || !userInfo.token) {
        //用户未登录
        return <Redirect to="/Login" />;
    }
    const DatePickerHandler = (date) => {
        console.log('date', date);
        date = moment(date).format('YYYY');
        setSalaryYear(date);
    };
    const DownloadImg = (src) => {
        window.open(src)
    }
    const goSign = (id) => {
        history.push({
            pathname: '/SignCanvas',
            search: `id=${id}`,
        });
    }
    return (
        <Flex direction="column" className={styled.page}>
            <Flex className={styled.header}>
                <DatePicker
                    mode="year"
                    value={new Date(salaryYear)}
                    onOk={(date) => {
                        DatePickerHandler(date);
                    }}
                >
                    <Flex className={styled.DateSelect}>
                        <Flex.Item style={{ padding: '0 4px' }}>
                            {salaryYear}
                        </Flex.Item>
                        <Flex.Item>
                            <Icon type="down" size="md" color="#1296DB" />
                        </Flex.Item>
                    </Flex>
                </DatePicker>
                <img className={styled.banner} src={Banner} alt="" />
            </Flex>
            <Flex.Item className={styled.content}>
                {salaryList.map((item, key) => {
                    return (
                        <div className={styled.ls} key={key}>
                            <div className={styled.ls_top}>
                                <div className={styled.ls_time}>{item.monthPart}</div>
                                {
                                    item.status != 3 ? <Button type="warning" inline size="small" style={{ marginRight: '4px' }} onClick={goSign.bind(this, item.id)}>签字</Button> : null
                                }

                            </div>
                            <div className={styled.ls_img_box} onClick={DownloadImg.bind(this, `${BASEURL}${item.pngPath}`)}>
                                <img src={`${BASEURL}${item.pngPath}`} className={styled.ls_img} />
                            </div>
                        </div>
                    );
                })}
            </Flex.Item>

        </Flex >
    );
};
export default SalarySheet;
