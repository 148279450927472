import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useHistory, useLocation } from 'react-router-dom';
import quertString from 'query-string';
import './SignCanvas.scss';
function SignCanvas() {
    const history = useHistory();
    const signCanvas = useRef();
    const [sign, setSign] = useState('');
    useEffect(() => {
        // 竖屏模式下横过来，横屏状态下不变
        var width = document.documentElement.clientWidth;
        var height = document.documentElement.clientHeight;
        if (width < height) {
            // detectOrient();
        }
    }, [])
    let location = useLocation();
    const { id } = quertString.parse(location.search);
    const confirmSign = () => {
        const sign = signCanvas.current.toDataURL('image/png');
        let file = sign.split(",")[1]
        let fileName = 'sign.png'
        React.$$.signSalarySheet(id, file, fileName)
            .then((res) => {
                history.goBack()
            })
            .catch((error) => { });
    }

    const detectOrient = function (id = 'content') {
        //先将整个h5页面翻转
        let width = document.documentElement.clientWidth,
            height = document.documentElement.clientHeight,
            $wrapper = document.getElementById(id),
            style = '', canvasStyle = ''; style += 'width:' + height + 'px;';
        style += 'height:' + width + 'px;';
        style += '-webkit-transform: rotate(90deg); transform: rotate(90deg);';
        style += '-webkit-transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
        style += 'transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
        $wrapper.style.cssText = style;
        //将签名还原翻转，就可以保证在横屏情况下保证画笔的方向跟手势一致，然后再进行高度和宽度的调整。
        let parentElement = document.getElementById('sign-content');
        let pw = parentElement.clientWidth;
        let ph = parentElement.clientHeight;
        parentElement.style.cssText = 'height: ' + ph + 'px;padding: 0 20px;';
        let canvasElement = signCanvas.current.getCanvas();
        canvasElement.height = pw - 40;
        canvasElement.width = ph;
        canvasStyle += '-webkit-transform: rotate(-90deg); transform: rotate(-90deg);';
        canvasStyle += '-webkit-transform-origin: ' + ph / 2 + 'px ' + ph / 2 + 'px;';
        canvasStyle += 'transform-origin: ' + ph / 2 + 'px ' + ph / 2 + 'px;';
        canvasStyle += 'background: #f2f2f2'; canvasElement.style.cssText = canvasStyle;
    };
    return (
        <div className="sign" id="content">
            <div className="sign-container">
                <div className="salary-sign-container">
                    <div className="salary-sign-title">
                        请签名确认
                    </div>

                    <div id="sign-content" className="salary-sign-content" >
                        <SignatureCanvas
                            ref={signCanvas}
                            penColor="black"
                            backgroundColor="#f2f2f2"
                            canvasProps={{ width: 650, height: 200 }}
                        />
                    </div>
                    <div className="salary-sign-bar">
                        <div className="salary-sign-btn" onClick={() => { history.goBack() }}>取消</div>
                        <div className="salary-sign-btn" onClick={() => { confirmSign() }}>确认</div>
                    </div>
                </div>
            </div>
        </div>);
}

export default SignCanvas;