import { createForm } from 'rc-form';
import {
	Flex,

} from 'antd-mobile';
import styled from './Agreement.module.scss';


const Agreement = (props) => {
	
	return (
		<div className={styled.page}>
            <div className={styled.title}>数字证书使用协议</div>
            <div className={styled.info}>
                <div className={styled.tip}>声明：</div>
                <div className={styled.fs}>1.您应当认真阅读并遵守《数字证书使用协议》（以下简称“本协议”），本协议具有合同效力，<span className={styled.fw}>当您勾选确认或点击确认并完成注册后即成立生效。</span></div>
                <div className={styled.fs}>2.您务必审慎阅读、充分理解各条款内容，如您对本协议有任何疑问或对任何条款不能准确理解的，请不要进行后续操作。</div>
                <div className={styled.fw}>3.您承诺接受并遵守本协议的约定，届时您不可以未阅读本协议内容或未获得杭州尚尚签网络科技有限公司（以下简称“上上签”）对您问询的解答等理由，主张本协议无效，或要求撤销本协议。</div>
                <div className={styled.fs}>4.本数字证书服务协议更新日期为 2021年8月27日，将于2021年9月02日正式生效。<span className={styled.fw}>您一旦进行数字证书的申请（包括续期、更新）、下载或使用，即表明同意接受并愿意遵守本协议的所有条款。</span></div>
                <div className={styled.tit}>一、数字证书服务简介</div>
                <div className={styled.fs}>1.您同意并认可上上签与工信部许可的电子认证服务机构合作，为通过实名认证后的互联网个人或企业申请数字证书。</div>
                <div className={styled.fs}>2.您同意并认可通过上上签电子签约平台完成实名认证并使用数字证书获得电子签名服务，成为上上签电子签名体系不可分割的部分。</div>
                <div className={styled.fs}>3.本协议中的“证书”指个人数字证书或企业数字证书。</div>
                <div className={styled.tit}>二、名词解释</div>
                <div className={styled.fs}>1.订户：</div>
                <div className={styled.fs}>指委托上上签从第三方电子认证服务机构获得证书的个人、法人或其他组织机构。</div>
                <div className={styled.fs}>2.数字证书：</div>
                <div className={styled.fs}>是指一段信息，它至少包含了一个名字，标识特定的CA或标识特定的订户，包含了订户的公钥、证书有效期、证书序列号，及CA数字签名。</div>
                <div className={styled.fs}>3.证书私钥：</div>
                <div className={styled.fs}>数字证书包含证书本身和一个密钥对，密钥对的一部分是公钥，另一部分称为私钥。公钥公之于众，谁都可以使用。私钥只有自己知道，一般信息都是由公钥进行加密，相对应的私钥进行解密。</div>
                <div className={styled.tit}>三、订户的权利和义务</div>
                <div className={styled.fs}>1.您应按照上上签规定的证书申请流程向上上签平台提供有关资料，并保证所填写的注册信息和所提供的资料的真实性、准确性和完整性，否则上上签平台有权拒绝您的申请请求。 <span className={styled.fw}>在这些信息、资料发生改变时您应当及时通知上上签。如因您的资料不真实、不完整、不准确或资料改变后未及时通知上上签，造成的损失由订户自己承担。</span></div>
                <div className={styled.fs}>2.您的证书信息在证书有效期限内变更的，应当及时书面告知上上签，并立即终止使用该证书（注：企业名称变更、企业统一社会信用代码变更、经营期限届满、姓名变更、身份证号变更等需要吊销原证书，重新申请数字证书）。</div>
                <div className={styled.fs}>3.若您为企业用户，您企业因分立、合并、解散、注销、宣告破产或倒闭，或被吊销营业执照等导致主体资格终止的，应于上述情况发生前书面告知上上签申请吊销数字证书，并立即终止使用该证书，否则，因未尽该通知义务给上上签造成损失的，由您全部赔偿。</div>
                <div className={styled.fw}>4.您同意上上签平台向有关部门和个人核实您的信息。上上签平台应合法地收集、处理、传递及使用您的资料，并按照国家有关规定及本协议的约定予以保密。</div>
                <div className={styled.fs}>5.您对证书享有独立的使用权， 您应当合法使用数字证书签署电子文件，并对使用数字证书的行为负责。您使用证书产生的权利，由您享有；您使用证书产生的义务、责任，由您承担。</div>
                <div className={styled.fw}>6.您应当对您所专有并控制的数字证书及上上签账户进行妥善保管，当您在上上签账户设置的通知方式（手机和邮箱）收到签署验证码时，应确保签署请求由本人发起，且对验证码承担保密义务。如您使用的数字证书私钥和密码泄漏、丢失，或者您不希望继续使用数字证书，或者您的主体不存在，您应当立即向上上签申请废止该数字证书。因您原因致使证书私钥泄露、损毁或者丢失的，损失由您承担。</div>
                <div className={styled.fs}>7.您损害第三方电子认证服务机构利益的，须向电子认证服务机构赔偿全部损失。这些情形包括但不限于：</div>
                <div className={styled.fs}>①您在申请数字证书时没有提供真实、准确、完整信息，或在这些信息变更时未及时通知上上签；</div>
                <div className={styled.fs}>②您知道自己的私钥已经失密或者可能已经失密而未及时告知有关各方、并终止使用；</div>
                <div className={styled.fs}>8.第三方电子认证服务机构有权因安全风险因素要求上上签更换您的数字证书。</div>
                <div className={styled.fw}>9.您申请数字证书后，一旦发现如下情况之一时，应当立即向上上签申请吊销此证书：</div>
                <div className={styled.fw}>①证据表明，此数字证书被用于签署可疑代码，包括但不限于病毒，木马，或者其他不恰当的程序；</div>
                <div className={styled.fw}>②证书中内容不再正确或不再准确；</div>
                <div className={styled.fw}>③此证书私钥信息已被泄露、丢失，或者其他相关部分已被错误使用。</div>
                <div className={styled.fw}>10.证书一旦被吊销，订户将不能再使用该证书。</div>
                <div className={styled.fw}>11.如果第三方电子认证服务机构发现了证书的不当使用，或者证书被用于违法甚至犯罪行为，电子认证服务机构有权直接吊销您的证书。</div>
                <div className={styled.fw}>12.在发现或怀疑第三方电子认证服务机构提供的认证服务造成订户的网上交易信息的泄漏和/或篡改时，您应在3个月内向电子认证服务机构提出争议处理请求并通知有关各方。</div>
                <div className={styled.fw}>13.根据《电子签名法》的规定，对于与您认证相关的信息以及您签署的电子合同等，您同意上上签有权自您的电子签名认证证书失效后保存十年。</div>
                <div className={styled.fw}>14.您同意并授权上上签将您的身份信息和您签署的电子合同提供给国家司法机关、行政机关，具有司法行政职能的事业单位和社会团体以及电子合同其他签订方。</div>
                <div className={styled.tit}>四、证书的申请、使用、更新、吊销</div>
                <div className={styled.fs}>1. 您可以在上上签平台选择适合您的实名认证方式进行数字证书的申请，获得的证书和证书私钥后，由上上签托管，数字证书储存在上上签平台。证书仅用于上上签平台电子文件签署，上上签确保您使用证书和证书密钥制作的电子签名制作数据专属于您所有并由您控制，仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。</div>
                <div className={styled.fs}>2. 您每次在上上签平台签署电子文件都将使用您的数字证书。上上签只有核实了您的签署意愿后，才会依据您的意愿使用数字证书签署电子文件。<span className={styled.fw}>如果启用了指纹签署或自动盖章功能，视为您知情并同意每次签署电子文件的行为都使用您的数字证书。</span></div>
                <div className={styled.fw}>3. 本协议的有效期限为证书的有效期限，证书只能在数字证书有效期限内使用，证书有效期限届满，您需要继续使用的，应当及时办理证书更新手续，本协议有效期限顺延至证书更新期限届满日。在获得您将证书延期的授权前，上上签不会主动为您更新证书。</div>
                <div className={styled.fw}>4. 证书私钥在证书有效期内损毁、丢失、泄露的，您应当及时申请办理吊销手续，吊销自手续办妥时起生效，吊销生效前发生的损失由您承担。</div>
                <div className={styled.fs}>5. 您知悉证书私钥已经丢失或者可能已经丢失时，应当及时告知上上签平台。上上签将协助完成吊销该证书的工作。您应终止使用该证书。</div>
                <div className={styled.tit}>五、上上签的权利</div>
                <div className={styled.fw}>您有下列情形之一，上上签有权向第三方机构申请吊销证书并不承担任何责任。由此给上上签造成损失的，您应当向上上签承担赔偿责任：</div>
                <div className={styled.fs}>1.您向上上签平台提供的资料或者信息不真实、不准确或者不完整的。</div>
                <div className={styled.fs}>2.您证书的信息有变更，未终止使用该证书并通知上上签平台的。</div>
                <div className={styled.fs}>3.您知悉证书私钥已经丢失或者可能已经丢失时，未终止使用该证书并通知上上签平台的。</div>
                <div className={styled.fs}>4.您超过证书的有效期限及应用范围使用证书的。</div>
                <div className={styled.fs}>5.您企业因分立、合并、解散、注销、宣告破产或倒闭，被吊销营业执照等导致主体资格终止而您未及时通知上上签吊销书证书的。</div>
                <div className={styled.fs}>6.您使用证书用于违法、犯罪活动的。</div>
                <div className={styled.tit}>六、第三方电子认证服务机构的服务、权利、义务、责任限制和免责</div>
                <div className={styled.fs}>1. 第三方电子认证服务机构依法制定《电子认证业务规则》（简称CPS），并公布于第三方电子认证服务机构网站，明确第三方电子认证服务机构数字证书的功能、使用证书各方的权利、义务以及第三方电子认证服务机构的责任范围，本协议的相关条款源自CPS。包括但不限于：</div>
                <div className={styled.fs}>①《中国金融认证中心电子认证业务规则（CPS）》</div>
                <div className={styled.fs}>原文链接：http://www.cfca.com.cn/zhengshuzizhu/</div>
                <div className={styled.fs}>②《ZJCA电子认证业务规则》</div>
                <div className={styled.fs}>原文链接：https://www.zjca.com.cn/web/webs/service/download.html</div>
                <div className={styled.fs}>③《天威诚信电子认证业务规则》</div>
                <div className={styled.fs}>原文链接：https://www.itrus.com.cn/uploads/soft/200714/guize.pdf</div>
                <div className={styled.fs}>2. 在订户通过安全工具使用数字证书对交易信息进行加密和签名的条件下，第三方电子认证服务机构将保证交易信息的保密性、完整性、抗抵赖性。如果发生纠纷，第三方电子认证服务机构将依据不同情形承担下述义务：</div>
                <div className={styled.fs}>①提供签发订户数字证书的CA证书；</div>
                <div className={styled.fs}>②提供订户数字证书在交易发生时，在或不在第三方电子认证服务机构发布的数字证书废止列表内的证明；</div>
                <div className={styled.fs}>③对数字证书、数字签名、时间戳的真实性、有效性进行技术确认。</div>
                <div className={styled.fw}>3.有下列情形之一的，第三方电子认证服务机构有权吊销所签发的数字证书：</div>
                <div className={styled.fw}>①订户申请数字证书时，提供的资料不真实、不准确、不完整；</div>
                <div className={styled.fw}>②订户未履行本协议约定的义务；</div>
                <div className={styled.fw}>③订户书面申请吊销数字证书；</div>
                <div className={styled.fw}>④证书的安全性不能得到保证；</div>
                <div className={styled.fw}>4. 第三方电子认证服务机构将对订户申请数字证书时提交的信息进行审核，提供证书生命周期內的相关服务，同时向相关方提供查询服务。第三方电子认证服务机构及其注册机构均有义务保护订户隐私信息安全性。</div>
                <div className={styled.fw}>5. 根据《电子签名法》的规定，如果订户依法使用第三方电子认证服务机构提供的认证服务进行民事活动而遭受损失的，第三方电子认证服务机构将给予相应赔偿，除非第三方电子认证服务机构能够证明其提供的服务是按照《电子签名法》等相关法律法规和第三方电子认证服务机构向主管部门备案的CPS实施的。以下损失不在赔偿之列：</div>
                <div className={styled.fw}>①任何直接或间接的利润或收入损失、信誉或商誉损害、任何商机或契机损失、失去项目、以及失去或无法使用任何数据、无法使用任何设备、无法使用任何软件；</div>
                <div className={styled.fw}>②由上述损失相应生成或附带引起的损失或损害。</div>
                <div className={styled.fw}>6. 以下损失第三方电子认证服务机构将不承担责任：</div>
                <div className={styled.fw}>①非因第三方电子认证服务机构的行为而导致的损失；</div>
                <div className={styled.fw}>②因不可抗力而导致的损失，如罢工、战争、灾害、电脑病毒、黑客攻击等。</div>
                <div className={styled.fw}>7. 第三方电子认证服务机构对企业订户申请的数字证书的赔偿上限以各电子认证服务机构的官方说明为准。</div>
                <div className={styled.tit}>七、免责条款</div>
                <div className={styled.fs}>1.因设备故障、电力故障及通讯故障或者电脑病毒、自然灾害、黑客攻击等因素造成您损失的，上上签不承担任何责任。</div>
                <div className={styled.fs}>2.由于第三方电子认证服务机构的原因导致证书私钥被破译、窃取，致使您遭受损失的，由第三方电子认证服务机构向您承担赔偿责任。</div>
                <div className={styled.fs}>3.上上签为您提供本协议服务过程中因违反本协议约定给您造成损失的（包括因违反约定造成您签署的法律文件被司法机关终审判定无效），上上签赔偿您的全部金额不超过向您收取的当次服务费用总额。如果服务费金额超过人民币五万元的，则以五万元为限。您与上上签签订的任何文件中有关赔偿责任限额与此约定不一致的，以此条约定为准。</div>
                <div className={styled.tit}>八、协议的终止</div>
                <div className={styled.fs}>有下列情形之一的，本协议终止：</div>
                <div className={styled.fw}>1.您证书期限届满。</div>
                <div className={styled.fw}>2.您证书被吊销。</div>
                <div className={styled.fw}>3.您向上上签申请终止本协议，上上签同意的。</div>
                <div className={styled.fs}>4.双方协商终止本协议的。</div>
                <div className={styled.fs}>5.依据法律、法规等规定，本协议应当终止的。</div>
                <div className={styled.tit}>九、争议解决条款</div>
                <div className={styled.fw}>对于任何因履行本协议发生的或与本协议有关的一切争议，双方应首先通过友好协商的方式解决。不能协商解决的，各方均应依照中华人民共和国法律并由被告住所地的人民法院裁判。</div>
            </div>
		</div>
	);
};
export default createForm()(Agreement);
